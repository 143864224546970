import React, { useRef } from "react";
import * as action from "../../service/action";

import "./Login.css";
import SpinnerButton from "../../components/Spinner/SpinnerButton";

const Signup = () => {
  const emailRef = useRef();
  const psdRef = useRef();

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = emailRef.current.value;
    const password = psdRef.current.value;

    if (email && password) {
      const response = await action.createUser(email, password);

      if (response.status !== 200) {
        alert(response.data.error);
      } else {
        alert("Your account has been created successfully");
        window.location.reload();
      }
    }
  };

  const [showLoader] = React.useState(false);

  return (
    <div className="form">
      <form>
        <input
          className="login-input"
          placeholder="Email"
          type="email"
          ref={emailRef}
          minLength="5"
          required
        />
        <input
          className="login-input"
          placeholder="Password"
          type="password"
          ref={psdRef}
          minLength="5"
          required
        />
        <SpinnerButton
          text="Register"
          onSubmit={onSubmit}
          loading={showLoader}
          disabled={showLoader}
        />
      </form>
    </div>
  );
};

export default Signup;
