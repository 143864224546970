import React, { useState, useRef, useEffect } from "react";
import { useUserContext } from "../Login/userContext";
import * as action from "../../service/action";
import { Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import Alert from "@mui/material/Alert";
import { blue } from "@mui/material/colors";
import { Button } from "reactstrap";

import "./photo.css";

import ImgCrop from "antd-img-crop";
import { Upload } from "antd";
const DEFAULTUSERPHOTO =
  "https://res.cloudinary.com/dn2gadqqz/image/upload/v1696913573/photos/6UaAlW.png";
const DEFAULTBACKGROUNDPHOTO =
  "https://res.cloudinary.com/dn2gadqqz/image/upload/v1696913792/photos/l86xxyitk2dzujcyutly.jpg";

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      pointer-events: none; /* Allow clicks to pass through the overlay to the input */
    }
  }
`;

export default function ManageGroup({
  groupData,
  color,
  secondaryColor,
  groupName,
}) {
  const [profilePhoto, setProfilePhoto] = useState(undefined);
  const [backgroundPhoto, setBackgroundPhoto] = useState(undefined);

  const [updateGroupLoading, setUpdateGroupLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const [url, setUrl] = useState(groupData.profile_photo || DEFAULTUSERPHOTO);
  const [backgroundUrl, setBackgroundUrl] = useState(
    groupData.background_photo || DEFAULTBACKGROUNDPHOTO
  );

  const UploadProfilePhotoProps = {
    name: "file",
    customRequest: () => {},
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUrl(e.target.result);
        setProfilePhoto(file);
      };
      reader.readAsDataURL(file); // convert to base64 string
      return false;
    },
  };

  const UploadBackgroundPhotoProps = {
    name: "backgroundFile",
    customRequest: () => {},
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setBackgroundUrl(e.target.result);
        setBackgroundPhoto(file);
      };
      reader.readAsDataURL(file); // convert to base64 string

      // Prevent upload
      return false;
    },
  };

  const { getUser } = useUserContext();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdateGroupLoading(true);

    const data = {
      primary_color: color,
      secondary_color: secondaryColor,
    };

    let status = 200;

    let response = await action.updateGroup(
      await getUser()?.getIdToken(),
      groupName,
      data
    );
    status = response.status;
    if (profilePhoto) {
      let photoData = {
        photoType: "profile",
        photo: profilePhoto,
      };
      response = await action.updateGroupPhoto(
        await getUser()?.getIdToken(),
        groupName,
        photoData
      );

      status = response.status;
    }

    if (backgroundPhoto) {
      let photoData = {
        photoType: "background",
        photo: backgroundPhoto,
      };
      response = await action.updateGroupPhoto(
        await getUser()?.getIdToken(),
        groupName,
        photoData
      );
      status = response.status;
    }

    setUpdateGroupLoading(false);
    if (status == 200 || status == 201) {
      setShowSuccess(true);
    } else {
      setShowError(true);
    }
  };

  const overlayTextStyle = {
    position: "absolute",
    top: "50%",
    right: "50%", // Center the text horizontally
    transform: "translate(50%, -50%)", // Center the text vertically
    color: "white",
    fontSize: "14px", // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "5px",
    borderRadius: "5px",
  };

  const backgroundImageStyle = {
    borderRadius: "25px",
    backgroundImage: `url(${backgroundUrl})`,
    position: "relative",
  };

  return (
    <Box sx={{ marginBottom: 4, justifyContent: "center" }}>
      <div
        style={backgroundImageStyle}
        className="page-header page-header-xs"
        data-parallax={true}
      >
        <ImgCrop quality={1} aspect={300 / 277} aspectSlider={true}>
          <Upload
            accept="image/png, image/jpeg"
            showUploadList={false}
            {...UploadBackgroundPhotoProps}
          >
            <div style={overlayTextStyle}>Upload Default Background Photo</div>
          </Upload>
        </ImgCrop>
      </div>
      <div className="profile-content">
        <div className="owner">
          <div className="avatar" style={{ marginBottom: "2px" }}>
            <div className="vince">
              <div className="avatar-container">
                <img src={url} alt="Avatar" className="avatar-image" />
                <ImgCrop cropShape="round" quality={1}>
                  <Upload
                    style={{ minWidth: "280px", alignItems: "center" }}
                    accept="image/png, image/jpeg"
                    showUploadList={false}
                    beforeUpload={() => false}
                    {...UploadProfilePhotoProps}
                  >
                    <div className="overlay-text">
                      {" "}
                      Upload Default Profile Photo
                    </div>
                  </Upload>
                </ImgCrop>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Stack
        spacing={1}
        direction="column"
        sx={{ marginBottom: 4 }}
        style={{ justifyContent: "center" }}
      >
        <Button
          style={{ backgroundColor: color, color: secondaryColor }}
          className="btn-round align-center"
        >
          Facebook
        </Button>
        <Button
          style={{ backgroundColor: color, color: secondaryColor }}
          className="btn-round align-center"
        >
          Instagram
        </Button>
        <Button
          style={{ backgroundColor: color, color: secondaryColor }}
          className="btn-round align-center"
        >
          Twitter
        </Button>
        <Button
          style={{ backgroundColor: color, color: secondaryColor }}
          className="btn-round align-center"
        >
          LinkedIn
        </Button>
        <Button
          style={{ backgroundColor: color, color: secondaryColor }}
          className="btn-round align-center"
        >
          Youtube
        </Button>
      </Stack>
      <LoadingButton
        loading={updateGroupLoading}
        color="secondary"
        variant="outlined"
        onClick={handleSubmit}
      >
        {" "}
        Update Group{" "}
      </LoadingButton>
      <Alert
        severity="success"
        onClose={() => setShowSuccess(false)}
        hidden={!showSuccess}
      >
        Your group settings has been successfully updated
      </Alert>
      <Alert
        severity="error"
        onClose={() => setShowError(false)}
        hidden={!showError}
      >
        Could not update your group, please try again or email
        bappacards@gmail.com
      </Alert>
    </Box>
  );
}
