import React, { useState, useEffect } from "react";
import "./LoadingScreen.css"; // Create this stylesheet

const imageMap = {
  default: require("../../images/bappa-logo-black.png"),
  monroe: require("../../images/monroe.png"),
  test: require("../../images/test-image.png"),
};

const LoadingScreen = () => {
  const [loadingImage, setLoadingImage] = useState(imageMap.default);

  useEffect(() => {
    // Get the current URL search parameters
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get a specific query parameter by name
    const paramValue = urlSearchParams.get("group");

    if (paramValue) {
      setLoadingImage(imageMap[paramValue]);
    }
  }, []);

  return (
    <div className="loading-screen">
      <img width="50px" src={loadingImage} alt="Custom Logo" className="logo" />
      <div className="loading-text">Loading...</div>
    </div>
  );
};

export default LoadingScreen;
