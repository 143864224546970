import React, { useState, useEffect } from "react";
import Signin from "./signin";
import Signup from "./signup";
import ForgotPassword from "./forgotpassword";
import "./Login.css";

const Login = () => {
  const [index, setIndex] = useState(false);

  const toggleIndex = () => {
    setIndex((prevState) => !prevState);
  };

  useEffect(() => {
    const inputs = document.querySelectorAll(".input-field");
    const toggle_btn = document.querySelectorAll(".toggle");
    const main = document.querySelector("main");
    const bullets = document.querySelectorAll(".bullets span");
    const images = document.querySelectorAll(".image");

    inputs.forEach((inp) => {
      inp.addEventListener("focus", () => {
        inp.classList.add("active");
      });
      inp.addEventListener("blur", () => {
        if (inp.value !== "") return;
        inp.classList.remove("active");
      });
    });

    toggle_btn.forEach((btn) => {
      btn.addEventListener("click", () => {
        main.classList.toggle("sign-up-mode");
      });
    });

    function moveSlider() {
      let index = this.dataset.value;

      let currentImage = document.querySelector(`.img-${index}`);
      images.forEach((img) => img.classList.remove("show"));
      currentImage.classList.add("show");

      const textSlider = document.querySelector(".text-group");
      textSlider.style.transform = `translateY(${-(index - 1) * 4}rem)`;

      bullets.forEach((bull) => bull.classList.remove("active"));
      this.classList.add("active");
    }

    bullets.forEach((bullet) => {
      bullet.addEventListener("click", moveSlider);
    });
  });
  return (
    <main>
      <div className="login-container">
        <div className="inner-form">
          <div className="forms-wrap">
            <form action="/profile" autoComplete="off" className="sign-in-form">
              <div className="logo">
                <h4>Welcome back</h4>
              </div>

              <div>
                <p className="form-description welcome-message">
                  Welcome! Please enter your details to get signed in to your
                  account.
                </p>
                <h6 className="welcome-message"> Don't have an account?</h6>
                <div className="heading">
                  <a className="toggle welcome-message" href="#">
                    <strong className="welcome-message">Sign up</strong>
                  </a>
                </div>
              </div>

              <div className="login-form">
                {!index ? <Signin /> : <ForgotPassword />}
                <div className="forgot-password">
                  <p onClick={toggleIndex}>
                    {!index
                      ? "Forgot Password? Click Here "
                      : "Return to Sign In"}
                  </p>
                </div>
                {/* <input type="submit" value="Sign In" class="sign-btn" />*/}
              </div>
            </form>

            <form action="/profile" autoComplete="off" className="sign-up-form">
              <div className="logo">
                <h4>Create account</h4>
              </div>

              <div className="heading" class="heading">
                <h6>Already have an account?</h6>
                <a className="toggle" class="toggle">
                  Sign in
                </a>
              </div>

              <div className="login-form">
                <Signup />

                <p className="text">
                  By signing up, I agree to the &nbsp;
                  <a>Terms of Services&nbsp;</a> and&nbsp;&nbsp;
                  <a>Privacy Policy</a>
                </p>
              </div>
            </form>
          </div>

          <div className="carousel">
            <div className="images-wrapper">
              <img
                src={require("../../images/10.jpeg")}
                className="image img-1 show"
                alt="computer"
              />
              <img
                src={require("../../images/21.jpeg")}
                className="image img-2"
                alt="card"
              />
              <img
                src={require("../../images/20.jpeg")}
                className="image img-3"
                alt="city"
              />
            </div>

            <div className="text-slider">
              <div className="text-wrap">
                <div className="text-group">
                  <h2>Create your own cards</h2>
                  <h2>Customize as you like</h2>
                  <h2>Invite friends to your connection</h2>
                </div>
              </div>

              <div className="bullets" class="bullets">
                <span className="active" data-value="1"></span>
                <span data-value="2"></span>
                <span data-value="3"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
