import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ManageGroup from "./ManageGroup";
import { Stack, Box } from "@mui/material";
import * as action from "../../service/action";
import ColorPicker from "material-ui-color-picker";
import { useParams } from "react-router-dom";
import { GetColorName } from "hex-color-to-color-name";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";

import { useUserContext } from "../Login/userContext";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

function createData(user) {
  return {
    name: `${user.firstname} ${user.lastname}`,
    taps_today: user.amount_scanned_today,
    total_taps: user.amount_scanned,
    profile_id: user.profile_id,
  };
}

const defaultTheme = createTheme();

export default function GroupProfile() {
  const { id } = useParams();
  const { getUser } = useUserContext();
  const [groupData, setGroupData] = useState(undefined);
  const [color, setColor] = useState(
    groupData?.settings?.primary_color || "#101522"
  );
  const [secondaryColor, setSecondaryColor] = useState(
    groupData?.settings?.secondaryColor || "#ffffff"
  );

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const groupDataResponse = await action.getGroup(token, id);
    console.log(groupDataResponse);
    setGroupData(groupDataResponse);
    setColor(groupDataResponse?.primary_color || "#101522");
    setSecondaryColor(groupDataResponse?.secondary_color || "#ffffff");
  };

  const rows =
    groupData?.users
      .sort((a, b) => {
        return b.amount_scanned - a.amount_scanned;
      })
      .map((user) => createData(user)) || [];

  useEffect(() => {
    groupData === undefined ? fetchData() : fetchData();
  }, []);

  if (!groupData) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Stack direction="row">
        <Container
          component="main"
          maxWidth="xl"
          sx={{ mb: 4, justifyContent: "left", alignItems: "baseline" }}
        >
          <Container maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography component="h1" variant="h4" align="center">
                Manage {id && id[0].toUpperCase() + id.slice(1)} Group
              </Typography>
              <Accordion style={{ marginTop: 20, marginBottom: 60 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    sx={{ width: "100%", textTransform: "uppercase" }}
                    alignContent="center"
                  >
                    Members
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {" "}
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 200 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Taps Today</TableCell>
                          <TableCell align="right">Total Taps</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row) => (
                          <TableRow
                            key={row.name}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              onClick={() =>
                                window.open(
                                  `${window.location.origin}/profile/${row.profile_id}?group=${id}`,
                                  "_blank"
                                )
                              }
                            >
                              {row.name}
                            </TableCell>
                            <TableCell align="right">
                              {row.taps_today}
                            </TableCell>
                            <TableCell align="right">
                              {row.total_taps}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
              <Box sx={{ mb: "50px" }}>
                <Stack direction="column" spacing={2}>
                  Primary Color:{" "}
                  <ColorPicker
                    name="color"
                    placeholder={GetColorName(color)}
                    helperText={GetColorName(color)}
                    value={color}
                    onChange={(color) => setColor(color)}
                    style={{ marginBottom: "20px" }}
                  />
                  Secondary Color
                  <ColorPicker
                    name="color"
                    helperText={GetColorName(secondaryColor)}
                    placeholder={GetColorName(secondaryColor)}
                    value={secondaryColor}
                    onChange={(secondaryColor) =>
                      setSecondaryColor(secondaryColor)
                    }
                  />
                </Stack>
              </Box>
              <React.Fragment>
                <ManageGroup
                  groupData={groupData}
                  color={color}
                  secondaryColor={secondaryColor}
                  groupName={id}
                />
              </React.Fragment>
            </Paper>
          </Container>
        </Container>
      </Stack>
    </ThemeProvider>
  );
}
