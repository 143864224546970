import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "./Auth";

export const ADMIN_EMAILS = [
  "vinstonguillaume@gmail.com",
  "tevinskeete55@gmail.com",
  "aymanali180@gmail.com",
  "tskeete@bappacards.com"
];

const AdminRoute = ({ redirectPath = "/dashboard", children }) => {
  const { currentUser } = useContext(AuthContext);

  if (!ADMIN_EMAILS.includes(currentUser.email)) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

export default AdminRoute;
