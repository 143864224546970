import React, { useState } from "react";
import { useUserContext } from "../Login/userContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "reactstrap";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";
import FileSaver from "file-saver";
import Lottie from "lottie-react";
import saveContact from "../../assets/lottie/save-contact.json";
import contactLogo from "../../images/contact-icon.png";
import { blue } from "@mui/material/colors";
import { generateVCard } from "../../service/vcard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const lighterBlue = blue[700];

const getFontSize = (textLength) => {
  const width = window.innerWidth / 14;
  const baseSize = width;

  if (textLength >= baseSize) {
    textLength = baseSize - 8;
  }
  const fontSize = baseSize - textLength;

  if (fontSize < 40) {
    return `${fontSize}px`;
  } else {
    return "40px";
  }
};

const getImageSize = () => {
  const size = window.innerWidth / 15;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

const getPaddingSize = () => {
  const size = window.innerWidth / 30;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

const SaveContact = ({ open, handleClose, profile }) => {
  const downloadVcard = async () => {
    const vcard = await generateVCard(profile.links);
    console.log(vcard);
    const blob = new Blob([vcard], {
      type: "text/vcard;charset=utf-8",
    });
    FileSaver.saveAs(blob, "vcard.vcf");
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
          style={{
            paddingBottom: 20,
            fontWeight: "bold",
            color: lighterBlue,
            borderBottom: `2px solid ${lighterBlue}`,
          }}
        >
          Scroll down to Create New Contact
        </Typography>
        <Stack direction="column" spacing={2}>
          <Lottie animationData={saveContact} loop={true} autoPlay={true} />

          <Button
            hidden={!profile.preferences.showSaveContactButton}
            className="btn-round align-center"
            color="default"
            style={{
              fontSize: getFontSize("Save Contact".length),
              backgroundColor: "rgb(16, 21, 34)",
              color: "#fff",
              alignItems: "center", // Add alignItems: center
            }}
            outline
            onClick={downloadVcard}
          >
            <img
              alt="save"
              className="img-no-padding"
              src={contactLogo}
              style={{
                maxHeight: getImageSize(),
                paddingRight: getPaddingSize(),
              }}
            ></img>{" "}
            Save Contact
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
export default SaveContact;
