import React from "react";
import { ReactComponent as Loader } from "../../images/loader.svg";
import "./SpinnerButton.css";

const SpinnerButton = ({ onSubmit, text, loading = false }) => {
  return (
    <>
      <button className="spinnerbtn profile-btn" onClick={onSubmit}>
        <span> {!loading ? text : <Loader className="spinner" />} </span>
      </button>
    </>
  );
};

export default SpinnerButton;
