import React, { useContext } from "react";
import { Route, Navigate } from "react-router-dom";
import { AuthContext } from "./Auth";



const PrivateRoute = ({ redirectPath = '/log-in', children }) => {
    const {currentUser} = useContext(AuthContext);

    if (!currentUser) {
      return <Navigate to={redirectPath} replace />;
    }
  
    return children
    // return <Navigate to={"/home"}/>
  };

// const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
//   const {currentUser} = useContext(AuthContext);
//   return (
//     <Route
//       {...rest}
//       render={routeProps =>
//         !!currentUser ? (
//           <RouteComponent {...routeProps} />
//         ) : (
//           <Navigate to={"/login"} />
//         )
//       }
//     />
//   );
// };


export default PrivateRoute