import React from "react";
import "./Contact.css";

const Contact = () => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d106045.52104247305!2d-84.48250495401716!3d33.840108380998416!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f50f5c4bd21ff5%3A0x3ae702c9bbf5477f!2sBuckhead%2C%20Atlanta%2C%20GA!5e0!3m2!1sen!2sus!4v1687356452487!5m2!1sen!2sus"
            width="600"
            height="400"
            className="border-0 w-100"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <div className="from-container">
        <h1>Send us a message!</h1>
        <p>
          We are passionate about finding solutions that work for you. Reach out
          during the hours of 10AM to 8PM EST Monday through Friday and we'll
          happily answer your questions. For further inquiries, please send an
          email to bappacards@gmail.com
        </p>
        <form>
          <input placeholder="Name" />
          <input placeholder="Email" />
          <input placeholder="Subject" />
          <textarea placeholder="Message" rows="4"></textarea>
          <button>Send Message</button>
        </form>
      </div>
    </>
  );
};

export default Contact;
