import React from "react"
import { featured } from "../../pages/HomePage/Data"

const FeaturedCard = () => {
  return (
    <>
      <div className='contents grid5 mtop'>
        {featured.map((items, index) => (
          <div className='box' key={index}>
            <img src={items.cover} alt='' />
            <h4>{items.name}</h4>
            <p className="labels">{items.total}</p>
          </div>
        ))}
      </div>
    </>
  )
}

export default FeaturedCard