import React, { useState, useEffect, useContext } from "react";
import { ADMIN_EMAILS } from "../AdminRoute";
import {
  Nav,
  NavbarContainer,
  NavLogo,
  NavIcon,
  HamburgerIcon,
  NavMenu,
  NavItem,
  NavLinks,
  NavItemBtn,
  NavBtnLink,
} from "./Navbar.elements";
import { FaTimes, FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { Button } from "../../globalStyles";
import { AuthContext } from "../Auth";
import { useUserContext } from "../../pages/Login/userContext";

const navbarMap = {
  default: {
    image: require("../../images/bappa-logo.png"),
    text: "Bappa Cards",
    size: {
      height: "60px",
      width: "40px",
    },
  },
  monroe: {
    image: require("../../images/monroe-white.png"),
    text: "Monroe College",
    size: {
      height: "50px",
      width: "50px",
    },
  },
  test: {
    image: require("../../images/test-image.png"),
    text: "Test",
    size: {
      height: "50px",
      width: "50px",
    },
  },
};

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const [homeClick, setHomeClick] = useState(false);
  const [servicesClick, setServicesClick] = useState(false);
  const [productsClick, setProductsClick] = useState(false);
  const [profileClick, setProfileClick] = useState(false);
  const [dashboardClick, setDashboardClick] = useState(false);
  const [contactClick, setContactClick] = useState(false);
  const [contactsClick, setContactsClick] = useState(false);

  const [adminClick, setAdminClick] = useState(false);

  const { currentUser } = useContext(AuthContext);
  const { logoutUser } = useUserContext();
  const [navbarData, setNavbarData] = useState(navbarMap.default);

  const handleHomeClick = () => {
    setHomeClick(true);
    setProductsClick(false);
    setServicesClick(false);
    setProfileClick(false);
    setDashboardClick(false);
    setContactsClick(false);
    setContactClick(false);
    setContactsClick(false);
    setAdminClick(false);
  };
  const handleServicesClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(true);
    setProfileClick(false);
    setDashboardClick(false);
    setContactClick(false);
    setContactsClick(false);
    setAdminClick(false);
  };
  const handleProductsClick = () => {
    setHomeClick(false);
    setProductsClick(true);
    setServicesClick(false);
    setProfileClick(false);
    setDashboardClick(false);
    setContactClick(false);
    setContactsClick(false);
    setAdminClick(false);
  };
  const handleProfileClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
    setProfileClick(true);
    setDashboardClick(false);
    setContactClick(false);
    setContactsClick(false);
  };
  const handleDashboardClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
    setProfileClick(false);
    setDashboardClick(true);
    setContactClick(false);
    setContactsClick(false);
  };

  const handleContactsClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
    setProfileClick(false);
    setDashboardClick(false);
    setContactClick(false);
    setContactsClick(true);
  };

  const handleContactClick = () => {
    setAdminClick(false);
  };

  const handleAdminClick = () => {
    setHomeClick(false);
    setProductsClick(false);
    setServicesClick(false);
    setProfileClick(false);
    setDashboardClick(false);
    setContactClick(true);
    setAdminClick(true);
    setContactsClick(false);
  };

  const handleClick = () => setClick(!click);

  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    // so if the screensize is <= 960px then set button state to false
    if (window.innerwidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();

    // Get the current URL search parameters
    const urlSearchParams = new URLSearchParams(window.location.search);

    // Get a specific query parameter by name
    const paramValue = urlSearchParams.get("group");

    if (paramValue) {
      setNavbarData(navbarMap[paramValue]);
    }
  }, []);

  window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <Nav>
          <NavbarContainer>
            <NavLogo
              to={navbarData.text != "Bappa Cards" ? window.location.href : "/"}
            >
              <NavIcon>
                <img
                  src={navbarData.image}
                  height={navbarData.size.height}
                  width={navbarData.size.width}
                />
              </NavIcon>
              {navbarData.text}
            </NavLogo>
            <HamburgerIcon onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </HamburgerIcon>
            <NavMenu onClick={handleClick} click={click}>
              <NavItem onClick={handleHomeClick} homeClick={homeClick}>
                <NavLinks to="/" onClick={closeMobileMenu}>
                  Home
                </NavLinks>
              </NavItem>

              {/*    <NavItem onClick={handleServicesClick} servicesClick={servicesClick}>
                            <NavLinks to='/services' onClick={closeMobileMenu}>
                                Services
                            </NavLinks>
                        </NavItem>
                    */}

              <NavItem
                onClick={handleProductsClick}
                productsClick={productsClick}
              >
                <NavLinks to="/products" onClick={closeMobileMenu}>
                  Products
                </NavLinks>
              </NavItem>
              {currentUser ? (
                <NavItem
                  onClick={handleDashboardClick}
                  dashboardClick={dashboardClick}
                >
                  <NavLinks to="/dashboard" onClick={closeMobileMenu}>
                    Manage Profile
                  </NavLinks>
                </NavItem>
              ) : (
                <div></div>
              )}

              {currentUser ? (
                <NavItem
                  onClick={handleContactsClick}
                  contactsClick={contactsClick}
                >
                  <NavLinks to="/contacts" onClick={closeMobileMenu}>
                    Contacts
                  </NavLinks>
                </NavItem>
              ) : (
                <div></div>
              )}

              {ADMIN_EMAILS.includes(currentUser?.email) ? (
                <NavItem onClick={handleAdminClick} adminClick={adminClick}>
                  <NavLinks to="/admin" onClick={closeMobileMenu}>
                    Admin
                  </NavLinks>
                </NavItem>
              ) : (
                <div></div>
              )}

              {/* 
                        <NavItem onClick={handleContactClick} contactClick={contactClick}>
                            <NavLinks to='/contact' onClick={closeMobileMenu}>
                                Contact Us
                            </NavLinks>
                        </NavItem> */}

              <NavItemBtn>
                {!currentUser ? (
                  <NavBtnLink to="/log-in">
                    <Button primary>Log In</Button>
                  </NavBtnLink>
                ) : (
                  <NavBtnLink to="/">
                    <Button onClick={logoutUser} primary>
                      Log Out
                    </Button>
                  </NavBtnLink>
                )}
              </NavItemBtn>
            </NavMenu>
          </NavbarContainer>
        </Nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;
