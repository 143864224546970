import React, { useRef } from "react";
import { useUserContext } from "./userContext";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import SpinnerButton from "../../components/Spinner/SpinnerButton";

const Signin = () => {
  const emailRef = useRef();
  const psdRef = useRef();
  const navigate = useNavigate();

  const { signInUser } = useUserContext();

  const onSubmit = async (e) => {
    e.preventDefault();
    setShowLoader(true);
    const email = emailRef.current.value;
    const password = psdRef.current.value;

    if (email && password) {
      if (await signInUser(email, password)) {
        navigate("/dashboard");
      } else {
        alert("Invalid Login");
      }
    }
    setShowLoader(false);
  };

  const [showLoader, setShowLoader] = React.useState(false);

  return (
    <div className="form">
      <form>
        <input
          className="login-input"
          placeholder="Email"
          type="email"
          ref={emailRef}
          minLength="5"
          required
        />
        <input
          className="login-input"
          placeholder="Password"
          type="password"
          ref={psdRef}
          minLength="1"
          required
        />
        <SpinnerButton
          text="Sign In"
          onSubmit={onSubmit}
          loading={showLoader}
          disabled={showLoader}
          className="spinnerbtn"
        />
      </form>
    </div>
  );
};

export default Signin;
