import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as action from "../../service/action";
import { useUserContext } from "../Login/userContext";
import { Stack } from "@mui/material";
import { AvatarInput } from "./ManageProfile";
import ExchangeContact from "./ExchangeContact";
import SaveContact from "./SaveContact";
import QrCodeModal from "./QrCodeModal";

import { QrCode } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { Button, Container } from "reactstrap";

import tiktokLogo from "../../images/tiktok-round-color-icon.png";
import instagramLogo from "../../images/instagram-color-icon.png";
import twitterLogo from "../../images/twitter-color-icon.png";
import snapchatLogo from "../../images/snapchat-color-icon.png";
import whatsappLogo from "../../images/whatsapp-round-color-icon.png";
import facebookLogo from "../../images/facebook-round-color-icon.png";
import cashappLogo from "../../images/cash-app-icon.png";
import youtubeLogo from "../../images/youtube-color-icon.png";
import linkedInLogo from "../../images/linkedin-app-icon.png";
import paypalLogo from "../../images/paypal-color-icon.png";
import venmoLogo from "../../images/venmo-icon.png";
import globeLogo from "../../images/globe.png";
import exchangeLogo from "../../images/exchange-icon.png";
import contactLogo from "../../images/contact-icon.png";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import ProfilePageHeader from "./ProfileHeader.js";

const getPaddingSize = () => {
  const size = window.innerWidth / 30;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

const getFontSize = (textLength) => {
  const width = window.innerWidth / 14;
  const baseSize = width;

  if (textLength >= baseSize - 3) {
    textLength = baseSize - 10;
  }
  const fontSize = baseSize - textLength;

  if (fontSize < 40) {
    return `${fontSize}px`;
  } else {
    return "40px";
  }
};

const getImageSize = () => {
  const size = window.innerWidth / 15;

  if (size < 40) {
    return size;
  } else {
    return 40;
  }
};

const getButtonBackgroundColor = (profile) => {
  if (profile.group === "test") {
    return profile.group_settings.primary_color;
  }

  return "rgb(16, 21, 34)";
};

const getButtonTextColor = (profile) => {
  if (profile.group === "test") {
    return profile.group_settings.secondary_color;
  }

  return "#fff";
};

function ProfilePage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState(undefined);
  const { getUser } = useUserContext();

  const [profilePhoto, setProfilePhoto] = useState(undefined);
  const [backgroundPhoto, setBackgroundPhoto] = useState(undefined);

  const openExchangeContactModal = () => setExchangeContactModalOpen(true);
  const closeExchangeContactModal = () => setExchangeContactModalOpen(false);
  const [exchangeContactModalOpen, setExchangeContactModalOpen] =
    React.useState(false);

  const openSaveContactModal = () => setSaveContactModalOpen(true);
  const closeSaveContactModal = () => setSaveContactModalOpen(false);
  const [saveContactModalOpen, setSaveContactModalOpen] = React.useState(false);

  const openQrCodeModal = () => setQrCodeModalOpen(true);
  const closeQrCodeModal = () => setQrCodeModalOpen(false);
  const [qrCodeModalOpen, setQrCodeModalOpen] = React.useState(false);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const profileResponse = await action.getProfile(id, token);

    if (profileResponse.status == 404) {
      navigate("/home");
    }
    await setProfile(profileResponse.data);

    const profileImage = new Image();

    profileImage.src = profileResponse.data.profile_photo;
    profileImage.onload = () => {
      setProfilePhoto(profileImage);
    };

    const backgroundImage = new Image();
    backgroundImage.src = profileResponse.data.background_photo;
    backgroundImage.onload = () => {
      setBackgroundPhoto(backgroundImage);
    };
  };

  useEffect(() => {
    profile === undefined ? fetchData() : fetchData();
  }, []);

  if (
    !profile ||
    !profile.background_photo ||
    !profile.profile_photo ||
    !profilePhoto ||
    !backgroundPhoto
  ) {
    return <LoadingScreen />;
  }

  return (
    <>
      <ExchangeContact
        open={exchangeContactModalOpen}
        handleClose={closeExchangeContactModal}
        profileId={profile.profile_id}
      />
      <SaveContact
        open={saveContactModalOpen}
        handleClose={closeSaveContactModal}
        profile={profile}
      />
      <QrCodeModal
        open={qrCodeModalOpen}
        handleClose={closeQrCodeModal}
        url={profile.profile_url}
      />
      <ProfilePageHeader photo_url={backgroundPhoto.src} />
      <div className="section profile-content">
        <Container>
          <div className="owner">
            <div className="avatar">
              <div className="vince">
                <AvatarInput>
                  <img
                    className="img-no-padding"
                    src={profilePhoto.src}
                    alt="Avatar Placeholder"
                  />
                </AvatarInput>
              </div>
            </div>
            <div className="name">
              <h4
                style={{
                  fontSize: getFontSize(
                    profile.firstname?.length + profile.lastname?.length - 10
                  ),
                  marginBottom: 0,
                }}
                className="title"
                onClick={openQrCodeModal}
              >
                {profile.firstname} {profile.lastname}
              </h4>
              <h6
                style={{
                  fontSize: getFontSize(profile.title?.length - 10),
                  color: "gray",
                }}
                // className="description-profile"
              >
                {profile.title}
              </h6>
            </div>
          </div>

          <Stack
            spacing={1}
            direction="column"
            sx={{ marginBottom: 4 }}
            style={{ justifyContent: "center" }}
          >
            <Button
              hidden={!profile.preferences.showSaveContactButton}
              className="btn-round align-center"
              color="default"
              style={{
                fontSize: getFontSize("Save Contact".length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "none",
              }}
              outline
              onClick={openSaveContactModal}
            >
              <img
                alt="save"
                className="img-no-padding"
                src={contactLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
              ></img>{" "}
              Save Contact
            </Button>
            <Button
              hidden={!profile.preferences.showExchangeContactButton}
              className="btn-round align-center"
              color="default"
              style={{
                fontSize: getFontSize("Save Contact".length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "none",
              }}
              outline
              onClick={openExchangeContactModal}
            >
              <img
                alt="save"
                className="img-no-padding"
                src={exchangeLogo}
                style={{
                  maxHeight: getImageSize() - 5,
                  paddingRight: getPaddingSize(),
                }}
              ></img>{" "}
              Exchange Contact
            </Button>
            {profile.websites.map((websites, i) => {
              return (
                <Button
                  className="btn-round align-center"
                  key={i}
                  style={{
                    fontSize: getFontSize(
                      websites.name ? websites.name.length : "website".length
                    ),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "none",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(
                      `https://${websites.url
                        .replace("https://", "")
                        .replace("http://", "")}`
                    )
                  }
                >
                  <img
                    alt="globe"
                    className="img-no-padding"
                    src={globeLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                  ></img>{" "}
                  {websites.name || "website"}
                </Button>
              );
            })}

            {profile.instagram_usernames.map((instagram_username, i) => {
              if (instagram_username == "") return;
              return (
                <Button
                  key={i}
                  className="btn-round align-center"
                  style={{
                    fontSize: getFontSize(instagram_username.length),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "lowercase",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(
                      `https://www.instagram.com/${instagram_username}`
                    )
                  }
                >
                  <img
                    alt="instagram"
                    className="img-no-padding"
                    src={instagramLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                  ></img>
                  @{instagram_username}
                </Button>
              );
            })}

            {profile.twitter_usernames.map((twitter_username, i) => {
              if (twitter_username == "") return;
              return (
                <Button
                  key={i}
                  className="btn-round align-center"
                  style={{
                    fontSize: getFontSize(twitter_username.length),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "lowercase",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(`https://www.twitter.com/${twitter_username}`)
                  }
                >
                  <img
                    alt="twitter"
                    className="img-no-padding"
                    src={twitterLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                  ></img>
                  @{twitter_username}
                </Button>
              );
            })}

            {profile.snapchat_usernames.map((snapchat_username, i) => {
              if (snapchat_username == "") return;
              return (
                <Button
                  key={i}
                  className="btn-round align-center"
                  style={{
                    fontSize: getFontSize(snapchat_username.length),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "lowercase",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(`https://snapchat.com/add/${snapchat_username}`)
                  }
                >
                  <img
                    alt="snapchat"
                    className="img-no-padding"
                    src={snapchatLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                  ></img>
                  @{snapchat_username}
                </Button>
              );
            })}

            {profile.tiktok_usernames.map((tiktok_username, i) => {
              if (tiktok_username == "") return;
              return (
                <Button
                  key={i}
                  className="btn-round align-center"
                  style={{
                    fontSize: getFontSize(tiktok_username.length),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "lowercase",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(`https://tiktok.com/@${tiktok_username}`)
                  }
                >
                  <img
                    className="img-no-padding"
                    src={tiktokLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                    alt="Avatar Placeholder"
                  />
                  @{tiktok_username}
                </Button>
              );
            })}

            <Button
              hidden={!profile.facebook_url}
              className="btn-round align-center"
              style={{
                fontSize: getFontSize("Facebook".length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() =>
                window.open(
                  profile.facebook_url.includes("facebook.com")
                    ? profile.facebook_url
                    : `https://facebook.com/${profile.facebook_url}`
                )
              }
            >
              <img
                className="img-no-padding"
                src={facebookLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />
              Facebook
            </Button>

            <Button
              hidden={!profile.youtube_url}
              className="btn-round align-center"
              style={{
                fontSize: getFontSize("youtube".length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() => window.open(profile.youtube_url)}
            >
              <img
                className="img-no-padding"
                src={youtubeLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />
              Youtube
            </Button>

            {profile.linkedin_urls.map((linkedin_url, i) => {
              if (linkedin_url == "") return;
              return (
                <Button
                  key={i}
                  className="btn-round align-center"
                  style={{
                    fontSize: getFontSize("LinkedIn".length),
                    backgroundColor: getButtonBackgroundColor(profile),
                    color: getButtonTextColor(profile),
                    textAlign: "left",
                    textTransform: "lowercase",
                  }}
                  color="default"
                  outline
                  onClick={() =>
                    window.open(
                      `https://${linkedin_url
                        .replace("https://", "")
                        .replace("http://", "")}`
                    )
                  }
                >
                  <img
                    className="img-no-padding"
                    src={linkedInLogo}
                    style={{
                      maxHeight: getImageSize(),
                      paddingRight: getPaddingSize(),
                    }}
                    alt="Avatar Placeholder"
                  />
                  LinkedIn {profile.linkedin_urls.length > 1 ? `${i + 1}` : ""}
                </Button>
              );
            })}

            <Button
              hidden={!profile.whatsapp_number}
              className="btn-round align-center"
              style={{
                fontSize: getFontSize("WhatsApp".length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() =>
                window.open(
                  profile.whatsapp_number.includes("http")
                    ? profile.whatsapp_number
                    : `https://wa.me/${profile.whatsapp_number}`
                )
              }
            >
              <img
                className="img-no-padding"
                src={whatsappLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />
              WhatsApp
            </Button>

            <Button
              hidden={!profile.cashapp_username}
              className="btn-round align-center"
              style={{
                fontSize: getFontSize(profile.cashapp_username?.length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() =>
                window.open(`https://cash.app/${profile.cashapp_username}`)
              }
            >
              <img
                className="img-no-padding"
                src={cashappLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />
              {profile.cashapp_username}
            </Button>

            <Button
              hidden={!profile.venmo_username}
              className="btn-round align-center text-left"
              style={{
                fontSize: getFontSize(profile.venmo_username?.length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() =>
                window.open(`https://venmo.com/${profile.venmo_username}`)
              }
            >
              <img
                className="img-no-padding"
                src={venmoLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />{" "}
              @{profile.venmo_username}
            </Button>

            <Button
              hidden={!profile.paypal_username}
              className="btn-round align-center text-left"
              style={{
                fontSize: getFontSize(profile.paypal_username?.length),
                backgroundColor: getButtonBackgroundColor(profile),
                color: getButtonTextColor(profile),
                textAlign: "left",
                textTransform: "lowercase",
              }}
              color="default"
              outline
              onClick={() =>
                window.open(`https://paypal.me/${profile.paypal_username}`)
              }
            >
              <img
                className="img-no-padding"
                src={paypalLogo}
                style={{
                  maxHeight: getImageSize(),
                  paddingRight: getPaddingSize(),
                }}
                alt="Avatar Placeholder"
              />{" "}
              @{profile.paypal_username}
            </Button>
          </Stack>

          <IconButton style={{ padding: 0 }} onClick={openQrCodeModal}>
            <QrCode sx={{ color: "black", fontSize: 80 }} />
          </IconButton>

          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
    </>
  );
}

export default ProfilePage;
