import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import GlobalStyles from "./globalStyles";
import { Navbar } from "./components";
import Home from "./pages/HomePage/Home";
import Services from "./pages/Services/Services";
import Products from "./pages/Products/Products";
import Contact from "./pages/ContactUs/Contact";

import ProfilePage from "./pages/Account/Profile";
import Admin from "./pages/Account/Admin";

import Login from "./pages/Login/Login";
import ScrollToTop from "./components/ScrollToTop";
import { AuthProvider } from "./components/Auth";

import PrivateRoute from "./components/PrivateRoute";
import AdminRoute from "./components/AdminRoute";
import UserProfile from "./pages/Account/UserProfile";
import GroupProfile from "./pages/Account/GroupProfile";

import Contacts from "./pages/Dashboard/Contacts";

import "bootstrap/scss/bootstrap.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/paper-kit.scss";
import "./assets/demo/demo.css?v=1.3.0";

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          {/* <GlobalStyles /> */}
          <ScrollToTop />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/services"
              element={
                <PrivateRoute>
                  <Services />
                </PrivateRoute>
              }
            />

            <Route
              path="/dashboard"
              element={
                <PrivateRoute>
                  <UserProfile />
                </PrivateRoute>
              }
            />

            <Route
              path="/group/:id"
              element={
                <AdminRoute>
                  <GroupProfile />
                </AdminRoute>
              }
            />

            <Route
              path="/contacts"
              element={
                <PrivateRoute>
                  <Contacts />
                </PrivateRoute>
              }
            />

            <Route
              path="/admin"
              element={
                <AdminRoute>
                  <Admin />
                </AdminRoute>
              }
            />

            <Route path="/products" element={<Products />} />
            <Route path="/log-in" element={<Login />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/profile/:id" element={<ProfilePage />} />
            <Route path="*" element={<Home />} />
          </Routes>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
