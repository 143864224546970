import React from "react"
import Headings from "./Headings"
import "./Subsection.css"
import FeaturedCard from "./FeaturedCard"

const Featured = () => {
  return (
    <>
      <section className='features backgrounds'>
        <div className='subsection'>
        <div className='headings'>
          <Headings title='How It Works' subtitle='A quick 5 step process to create your Bappacard ' />
          <FeaturedCard />
          </div>
        </div>
      </section>
    </>
  )
}

export default Featured