import React, { useState, useRef, useEffect } from "react";
import { useUserContext } from "../Login/userContext";
import * as action from "../../service/action";
import Switch from "@mui/material/Switch";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { TextField, Button, Container, Stack, Box } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import styled from "styled-components";
import RegisterCard from "../Dashboard/RegisterCard";
import Alert from "@mui/material/Alert";
import { blue } from "@mui/material/colors";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./photo.css";

import ImgCrop from "antd-img-crop";
import { Upload } from "antd";

const lighterBlue = blue[700];

const ImageOverlay = ({ imageUrl, overlayText }) => {
  return (
    <div className="image-overlay-container">
      <img src={imageUrl} alt="Overlay" className="overlay-image" />
      <div className="overlay-text">{overlayText}</div>
    </div>
  );
};

export const AvatarInput = styled.div`
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    object-fit: cover;
    border-radius: 50%;
  }
  .circle {
    width: 186px;
    height: 186px;
    border-radius: 50%;
  }
  label {
    right: 23em !important;
    position: absolute;
    width: 48px;
    height: 48px;
    background: #312e38;
    border-radius: 50%;
    right: 0;
    bottom: 0;
    border: 0;
    cursor: pointer;
    transition: background-color 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #f4ede8;
    }
    &:hover {
      background: blue;
    }

    .overlay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      pointer-events: none; /* Allow clicks to pass through the overlay to the input */
    }
  }
`;

export default function ManageProfile({ userData }) {
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);
  const [firstName, setFirstName] = useState(userData.links.firstname || "");
  const [lastName, setLastName] = useState(userData.links.lastname || "");
  const [title, setTitle] = useState(userData.links.title || "");
  const [email, setEmail] = useState(userData.links.email || "");
  const [phone, setPhone] = useState(userData.links.phone_number || "");
  const [instagrams, setInstagrams] = useState(
    userData.links.instagram_usernames || []
  );
  const [twitters, setTwitters] = useState(
    userData.links.twitter_usernames || []
  );
  const [snapchats, setSnapchats] = useState(
    userData.links.snapchat_usernames || []
  );
  const [tiktoks, setTiktoks] = useState(userData.links.tiktok_usernames || []);
  const [facebook, setFacebook] = useState(userData.links.facebook_url || "");
  const [youtube, setYoutube] = useState(userData.links.youtube_url || "");
  const [linkedins, setLinkedins] = useState(
    userData.links.linkedin_urls || ""
  );
  const [whatsapp, setWhatsapp] = useState(
    userData.links.whatsapp_number || ""
  );
  const [cashapp, setCashapp] = useState(userData.links.cashapp_username || "");
  const [paypal, setPaypal] = useState(userData.links.paypal_username || "");
  const [venmo, setVenmo] = useState(userData.links.venmo_username || "");
  const [customRedirect, setCustomRedirect] = useState(
    userData.links.custom_redirect
  );
  const [profilePhoto, setProfilePhoto] = useState(undefined);
  const [backgroundPhoto, setBackgroundPhoto] = useState(undefined);
  const [redirectEnabled, setRedirectEnabled] = useState(
    userData.links.custom_redirect != null
  );

  const [preferences, setPreferences] = useState(userData.links.preferences);

  const [registerCardModalOpen, setRegisterCardModalOpen] =
    React.useState(false);

  const [websiteMappings, setWebsiteMappings] = useState(
    userData.links.websites || [{ url: "", name: "" }]
  );
  const handleWebsiteMappig = (index, field, value) => {
    const updatedMappings = [...websiteMappings];
    updatedMappings[index][field] = value;
    setWebsiteMappings(updatedMappings);
  };

  const addMapping = () => {
    setWebsiteMappings([...websiteMappings, { url: "", name: "" }]);
  };

  const removeMapping = (index) => {
    const updatedMappings = websiteMappings.filter((_, i) => i !== index);
    setWebsiteMappings(updatedMappings);
  };

  const openRegisterCardModal = () => setRegisterCardModalOpen(true);
  const closeRegisterCardModal = () => setRegisterCardModalOpen(false);

  const [updateProfileLoading, setUpdateProfileLoading] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const [url, setUrl] = useState(userData.links.profile_photo);
  const [backgroundUrl, setBackgroundUrl] = useState(
    userData.links.background_photo
  );

  useEffect(() => {
    setButtonWidth(buttonRef.current.clientWidth);
  }, []);

  const beforeCrop = (file) => {
    if (file.type in ["image/heic"]) {
      alert("HEIC files are not supported, please use PNG or JPEG files");
      return false;
    }

    return true;
  };

  const UploadProfilePhotoProps = {
    name: "file",
    customRequest: () => {},
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload(file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        setUrl(e.target.result);
        setProfilePhoto(file);
      };
      reader.readAsDataURL(file); // convert to base64 string
      return false;
    },
  };

  const UploadBackgroundPhotoProps = {
    name: "backgroundFile",
    customRequest: () => {},
    action: "//jsonplaceholder.typicode.com/posts/",
    headers: {
      authorization: "authorization-text",
    },

    beforeUpload(file) {
      console.log("FILE");
      const reader = new FileReader();

      reader.onload = (e) => {
        setBackgroundUrl(e.target.result);
        setBackgroundPhoto(file);
      };
      reader.readAsDataURL(file); // convert to base64 string

      // Prevent upload
      return false;
    },
  };

  const { getUser } = useUserContext();
  const handleSubmit = async (event) => {
    event.preventDefault();
    setUpdateProfileLoading(true);

    const data = {
      firstname: firstName,
      lastname: lastName,
      title: title,
      email: email,
      phone_number: phone,
      instagram_usernames: instagrams,
      twitter_usernames: twitters,
      snapchat_usernames: snapchats,
      tiktok_usernames: tiktoks,
      facebook_url: facebook,
      youtube_url: youtube,
      linkedin_urls: linkedins,
      whatsapp_number: whatsapp,
      cashapp_username: cashapp,
      paypal_username: paypal,
      venmo_username: venmo,
      websites: websiteMappings,
      custom_redirect: customRedirect,
      preferences: preferences,
    };

    let status = 200;

    let response = await action.updateProfile(
      await getUser()?.getIdToken(),
      data
    );
    status = response.status;
    if (profilePhoto) {
      let photoData = {
        photoType: "profile",
        photo: profilePhoto,
      };
      response = await action.updatePhoto(
        await getUser()?.getIdToken(),
        photoData
      );

      status = response.status;
    }

    if (backgroundPhoto) {
      let photoData = {
        photoType: "background",
        photo: backgroundPhoto,
      };
      response = await action.updatePhoto(
        await getUser()?.getIdToken(),
        photoData
      );
      status = response.status;
    }

    setUpdateProfileLoading(false);
    if (status == 200 || status == 201) {
      setShowSuccess(true);
    } else {
      setShowError(true);
    }
  };

  const handleCustomRedirect = (event) => {
    setRedirectEnabled(event.target.checked);

    if (!event.target.checked) {
      setCustomRedirect("");
    }
  };

  const overlayTextStyle = {
    position: "absolute",
    top: "50%",
    right: "50%", // Center the text horizontally
    transform: "translate(50%, -50%)", // Center the text vertically
    color: "white",
    fontSize: "14px", // Adjust the font size as needed
    fontWeight: "bold",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "5px",
    borderRadius: "5px",
  };

  const backgroundImageStyle = {
    borderRadius: "25px",
    backgroundImage: `url(${backgroundUrl})`,
    position: "relative",
  };

  const profileSlug = userData.group
    ? `profile/${userData.profile_id}?group=${userData.group}`
    : `profile/${userData.profile_id}`;

  return (
    <FormControl fullWidth action={<Link to="/login" />}>
      <Container maxWidth="sm" sx={{ mb: 4 }}></Container>
      <RegisterCard
        open={registerCardModalOpen}
        handleClose={closeRegisterCardModal}
      />
      <Box sx={{ marginBottom: 4, justifyContent: "center" }}>
        <div
          style={backgroundImageStyle}
          className="page-header page-header-xs"
          data-parallax={true}
        >
          <ImgCrop
            quality={1}
            aspect={300 / 277}
            aspectSlider={true}
            beforeCrop={beforeCrop}
          >
            <Upload
              accept="image/png, image/jpeg"
              showUploadList={false}
              {...UploadBackgroundPhotoProps}
            >
              <div style={overlayTextStyle}>Upload Background Photo</div>
            </Upload>
          </ImgCrop>
          {/* Overlay Text */}
        </div>
        <div className="profile-content">
          <div className="owner">
            <div className="avatar" style={{ marginBottom: "2px" }}>
              <div className="vince">
                <div className="avatar-container">
                  <img src={url} alt="Avatar" className="avatar-image" />
                  <ImgCrop
                    beforeCrop={beforeCrop}
                    cropShape="round"
                    quality={1}
                  >
                    <Upload
                      style={{ minWidth: "280px", alignItems: "center" }}
                      accept="image/png, image/jpeg"
                      showUploadList={false}
                      {...UploadProfilePhotoProps}
                    >
                      <div className="overlay-text"> Upload Profile Photo</div>
                    </Upload>
                  </ImgCrop>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Button
          className="profile-btn"
          ref={buttonRef}
          onClick={openRegisterCardModal}
        >
          Register Card
        </Button>
        <Button
          className="profile-btn"
          onClick={() =>
            window
              .open(`${window.location.origin}/${profileSlug}`, "_blank")
              .focus()
          }
        >
          View Profile
        </Button>
        <Accordion style={{ marginTop: 20 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              sx={{ width: "100%", textTransform: "uppercase" }}
              alignContent="center"
              color={lighterBlue}
            >
              Preferences
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              spacing={1}
              direction="row"
              sx={{ marginBottom: 4 }}
              style={{ justifyContent: "center" }}
            >
              <Switch
                checked={preferences.showSaveContactButton}
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    showSaveContactButton: e.target.checked,
                  })
                }
              />{" "}
              Display Save Contact Button
              <Switch
                checked={preferences.showExchangeContactButton}
                onChange={(e) =>
                  setPreferences({
                    ...preferences,
                    showExchangeContactButton: e.target.checked,
                  })
                }
              />{" "}
              Display Exchange Contact Button
            </Stack>
            <Switch checked={redirectEnabled} onChange={handleCustomRedirect} />{" "}
            Custom Redirect
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Redirect Website"
              hidden={!redirectEnabled}
              onChange={(e) => setCustomRedirect(e.target.value)}
              helperText="When the 'Custom Redirect' switch is enabled, your card will load the website entered above instead of your profile"
              value={customRedirect}
              placeholder="https://www.website.com"
              fullWidth
            />
          </AccordionDetails>
        </Accordion>
      </Box>

      <div hidden={redirectEnabled}>
        <Stack spacing={2} direction="row" sx={{ marginBottom: 2 }}>
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            fullWidth
          />
          <TextField
            type="text"
            variant="outlined"
            color="secondary"
            label="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            fullWidth
          />
        </Stack>
        <TextField
          type="title"
          variant="outlined"
          color="secondary"
          label="Title"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          fullWidth
          sx={{ mb: 4 }}
        />
        <TextField
          type="email"
          variant="outlined"
          color="secondary"
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          fullWidth
          sx={{ mb: 4 }}
        />
        <TextField
          type="phone"
          variant="outlined"
          color="secondary"
          label="Phone"
          onChange={(e) => setPhone(e.target.value)}
          value={phone}
          fullWidth
          sx={{ mb: 4 }}
        />

        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              sx={{ width: "100%", textTransform: "uppercase" }}
              alignContent="center"
              color={lighterBlue}
            >
              Websites
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {websiteMappings.map((mapping, index) => (
              <div key={index}>
                <Stack spacing={2} direction="row" sx={{ marginTop: 2 }}>
                  <TextField
                    type="text"
                    fullWidth
                    sx={{ mb: 2 }}
                    color="secondary"
                    label="Website Title"
                    onChange={(e) =>
                      handleWebsiteMappig(index, "name", e.target.value)
                    }
                    value={mapping.name}
                  />

                  <TextField
                    type="text"
                    fullWidth
                    sx={{ mb: 2 }}
                    color="secondary"
                    label="Website URL"
                    onChange={(e) =>
                      handleWebsiteMappig(index, "url", e.target.value)
                    }
                    value={mapping.url}
                  />
                </Stack>

                <Button
                  className="profile-btn"
                  onClick={() => removeMapping(index)}
                  sx={{
                    marginBottom: 2,
                    color: "black",
                    "&:hover": { color: "red" },
                  }}
                >
                  Remove
                </Button>
              </div>
            ))}

            <Button className="profile-btn" onClick={addMapping}>
              +
            </Button>
          </AccordionDetails>
        </Accordion>

        <Accordion style={{ marginTop: 20, marginBottom: 20 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            style={{
              alignItems: "center",
            }}
          >
            <Typography
              align="center"
              sx={{ width: "100%", textTransform: "uppercase" }}
              alignContent="center"
              color={lighterBlue}
            >
              Socials
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack paddingTop={2} spacing={2} direction="row">
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Instagram(s)"
                multiline={true}
                onChange={(e) => setInstagrams(e.target.value.split("\n"))}
                value={instagrams.join("\r\n")}
                fullWidth
                sx={{ mb: 4 }}
                rows={2}
                placeholder="instagram_username1
        instagram_username2"
                helperText="For multiple accounts, put one on each line"
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Twitter(s)"
                onChange={(e) => setTwitters(e.target.value.split("\n"))}
                value={twitters.join("\r\n")}
                fullWidth
                multiline
                placeholder="twitter_username1
        twitter_username2"
                rows={2}
                sx={{ mb: 4 }}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="SnapChat(s)"
                placeholder="snapchat_username"
                onChange={(e) =>
                  setSnapchats(e.target.value.trim().split("\n"))
                }
                value={snapchats.join("\r\n").trim()}
                fullWidth
                multiline
                placeholder="snapchat_username1
        snapchat_username2"
                rows={2}
                sx={{ mb: 4 }}
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="TikTok"
                placeholder="tiktok_username1
        tiktok_username2"
                onChange={(e) => setTiktoks(e.target.value.split("\n"))}
                value={tiktoks.join("\r\n")}
                fullWidth
                multiline
                rows={2}
                sx={{ mb: 4 }}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Facebook"
                placeholder="https://www.facebook.com/thejohnsmith"
                onChange={(e) => setFacebook(e.target.value)}
                value={facebook}
                fullWidth
                sx={{ mb: 4 }}
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Youtube"
                placeholder="youtube.com/channel_name"
                onChange={(e) => setYoutube(e.target.value)}
                value={youtube}
                fullWidth
                sx={{ mb: 4 }}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="LinkedIn"
                onChange={(e) => setLinkedins(e.target.value.split("\n"))}
                placeholder="linkedin.com/user_name1
        linkedin.com/user_name2"
                value={linkedins.join("\r\n")}
                fullWidth
                multiline
                rows={2}
                sx={{ mb: 4 }}
              />

              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="WhatsApp"
                onChange={(e) => setWhatsapp(e.target.value)}
                value={whatsapp}
                fullWidth
                sx={{ mb: 4 }}
              />
            </Stack>
            <Stack spacing={2} direction="row">
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="CashApp"
                onChange={(e) => setCashapp(e.target.value)}
                value={cashapp}
                fullWidth
                sx={{ mb: 4 }}
              />
              <TextField
                type="text"
                variant="outlined"
                color="secondary"
                label="Paypal"
                onChange={(e) => setPaypal(e.target.value)}
                value={paypal}
                fullWidth
                sx={{ mb: 4 }}
              />
            </Stack>
            <TextField
              type="text"
              variant="outlined"
              color="secondary"
              label="Venmo"
              onChange={(e) => setVenmo(e.target.value)}
              value={venmo}
              fullWidth
              sx={{ mb: 4 }}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      {/* <Button variant="outlined" color="secondary" type="submit" onClick={handleSubmit}>Update</Button> */}
      <LoadingButton
        loading={updateProfileLoading}
        color="secondary"
        variant="outlined"
        onClick={handleSubmit}
      >
        {" "}
        Update Profile{" "}
      </LoadingButton>
      {/* <MaterialLink.default hidden={!showSuccess}  href={`/profile/${userData.profile_id}`}>Your profile has been successfully updated. Click here to see!</MaterialLink.default> */}

      <Alert
        severity="success"
        onClose={() => setShowSuccess(false)}
        hidden={!showSuccess}
      >
        <Link
          hidden={!showSuccess}
          onClick={() =>
            window
              .open(`${window.location.origin}/${profileSlug}`, "_blank")
              .focus()
          }
        >
          Your profile has been successfully updated. Click here to see!{" "}
        </Link>
      </Alert>
      <Alert
        severity="error"
        onClose={() => setShowError(false)}
        hidden={!showError}
      >
        Could not update your profile, please try again or email
        bappacards@gmail.com
      </Alert>
    </FormControl>
  );
}
