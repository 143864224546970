import React, { useRef } from "react";
import { useUserContext } from "./userContext";
import "./Login.css";
import SpinnerButton from "../../components/Spinner/SpinnerButton";

const ForgotPassword = () => {
  const emailRef = useRef();
  const { forgotPassword } = useUserContext();

  const forgotPasswordHandler = async () => {
    const email = emailRef.current.value;
    if (email)
      return await forgotPassword(email).then(() => {
        emailRef.current.value = "";
      });
  };
  const [showLoader, setShowLoader] = React.useState(false);

  const handleSubmit = async () => {
    setShowLoader(true);
    await forgotPasswordHandler();
    setShowLoader(false);
    alert(
      "A password reset has been submitted, please check your email for further instructions"
    );
  };

  return (
    <div className="form">
      {/*} <h2> Reset Password </h2>*/}
      <p className="form-description-reset">
        We'll send you instructions to reset your password.
      </p>
      <form action="javascript:void(0);">
        <input
          className="login-input"
          placeholder="Email"
          type="email"
          ref={emailRef}
          minLength="5"
          required
        />
        <SpinnerButton
          text="Reset Password"
          onSubmit={handleSubmit}
          loading={showLoader}
          disabled={showLoader}
        />
      </form>
    </div>
  );
};

export default ForgotPassword;
