export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Take a Look",
  headline: "Shop through our catalog of products",
  homeDescription:
    " Discover our wide range of sleek and professional designs that will elevate your personal brand and leave a lasting impression on professional clients and partners.",
  buttonLabel: "Shop Now",
  imgStart: "",
  img: require("../../images/3.jpg"),
  alt: "Credit Card",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "100% Secure",
  headline: "Real-time updates when modifying your profile",
  homeDescription:
    "Your digital card will always reflect the most current and accurate information, eliminating the need for reprinting or redistributing traditional business cards every time you make an update.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/23.jpeg"),
  alt: "Vault",
};
export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Easy Setup",
  headline: "Super fast and simple onboarding process",
  homeDescription:
    "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/3.jpg"),
  alt: "Credit Card",
};
