import React from "react";
import { Typography } from "@mui/material";

import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";

import { Container, Box } from "@mui/material";

import { List, ListItem, ListItemText, TextField } from "@mui/material";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";

const marks = [
  {
    value: 0,
    label: "All time",
  },
  {
    value: 50,
    label: "This Week",
  },
  {
    value: 100,
    label: "Today",
  },
];

const modes = {
  0: "All time",
  50: "This Week",
  100: "Today",
};

const valuetext = (value) => {
  return `${value}`;
};

export default function ListProfiles({ profilesData }) {
  const [mode, setMode] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState("");

  const citiesArray = Object.entries(profilesData.scan_data.scans_by_city).sort(
    (a, b) => {
      return a[1] > b[1] ? -1 : 1;
    }
  );

  const citiesArrayToday = Object.entries(
    profilesData.scan_data.scans_by_city_today
  ).sort((a, b) => {
    return a[1] > b[1] ? -1 : 1;
  });

  const citiesArrayThisWeek = Object.entries(
    profilesData.scan_data.scans_by_city_this_week
  ).sort((a, b) => {
    return a[1] > b[1] ? -1 : 1;
  });

  const getCityData = (mode) => {
    if (mode === 0) {
      return citiesArray;
    } else if (mode === 50) {
      return citiesArrayThisWeek;
    } else {
      return citiesArrayToday;
    }
  };

  const getSorting = (a, b) => {
    if (mode === 0) {
      return a.amount_scanned > b.amount_scanned ? -1 : 1;
    } else if (mode === 50) {
      return a.amount_scanned_this_week > b.amount_scanned_this_week ? -1 : 1;
    }
    return a.amount_scanned_today > b.amount_scanned_today ? -1 : 1;
  };

  const getAmountScanned = (profile) => {
    if (mode === 0) {
      return profile.amount_scanned;
    } else if (mode === 50) {
      return profile.amount_scanned_this_week;
    }
    return profile.amount_scanned_today;
  };

  return (
    <FormControl fullWidth action={<Link to="/login" />}>
      <Container maxWidth="sm" sx={{ mb: 4 }}></Container>
      <Typography component="h1" variant="h4" align="center">
        Profiles
      </Typography>

      <Typography component="h1" variant="h6" align="center">
        Total Profiles: {profilesData.profiles.length}
      </Typography>
      <Typography component="h1" variant="h6" align="center">
        Total Taps: {profilesData.scan_data.total_scans}
      </Typography>
      <Typography component="h1" variant="h6" align="center">
        Total Taps This Week: {profilesData.scan_data.total_scans_this_week}
      </Typography>
      <Typography component="h1" variant="h6" align="center">
        Total Taps Today: {profilesData.scan_data.total_scans_today}
      </Typography>

      <Slider
        aria-label="Scans"
        defaultValue={0}
        getAriaValueText={valuetext}
        step={null}
        // valueLabelDisplay="auto"
        onChange={(e, value) => setMode(value)}
        marks={marks}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></div>

      <div style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography component="h1" variant="h4" align="center">
          Taps By City {modes[mode]}
        </Typography>
        <ul style={{ listStyle: "none", padding: 0 }}>
          {getCityData(mode).map(([city, count]) => (
            <li key={city} style={{ marginBottom: "10px" }}>
              {city}: {count}
            </li>
          ))}
        </ul>
      </div>
      <Box sx={{ marginBottom: 4, justifyContent: "center" }}></Box>
      {/* const profileSlug = userData.group
    ? `profile/${userData.profile_id}?group=${userData.group}`
    : `profile/${userData.profile_id}`; */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        size="small" // Adjust the size for mobile
        sx={{ mb: 2 }} // Add margin-bottom for spacing
        InputProps={{
          sx: {
            borderRadius: 0, // Adjust border-radius as needed
          },
        }}
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />
      <List>
        {profilesData.profiles
          .filter((profile) => {
            return (
              profile.firstname
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase()) ||
              profile.lastname
                ?.toLowerCase()
                .includes(searchQuery.toLowerCase())
            );
          })
          .sort((a, b) => {
            return getSorting(a, b);
          })
          .map((profile) => (
            <ListItem
              hidden={!profile.email}
              key={profile.profile_id}
              button
              onClick={() =>
                window
                  .open(
                    profile.group
                      ? `${window.location.origin}/profile/${profile.profile_id}?group=${profile.group}`
                      : `${window.location.origin}/profile/${profile.profile_id}`,
                    "_blank"
                  )
                  .focus()
              }
            >
              <ListItemText
                primary={`${profile.firstname} ${profile.lastname} : ${
                  profile.email
                }: ${getAmountScanned(profile)} taps`}
              />
            </ListItem>
          ))}
      </List>
    </FormControl>
  );
}
