import React, { useEffect, useState } from "react";
import * as action from "../../service/action";
import { useUserContext } from "../Login/userContext";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Download";
import Button from "@mui/material/Button";
import FileSaver from "file-saver";
import { visuallyHidden } from "@mui/utils";
import { makeStyles } from "@material-ui/core";
import Papa from "papaparse";
import { generatVCardForContact } from "../../service/vcard.js";

import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";
import DeleteContactDialog from "./DeleteContact";

function createData(id, name, phone, email, dateMet, notes) {
  return {
    id,
    name,
    phone,
    email,
    dateMet,
    notes,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Phone",
  },
  {
    id: "Date Met",
    numeric: true,
    disablePadding: false,
    label: "Date Met",
  },
  {
    id: "Notes",
    numeric: true,
    disablePadding: true,
    label: "Notes",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              disabled={true}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
          align="center"
        >
          Contacts
        </Typography>
      )}
      <Tooltip
        hidden={numSelected !== 1}
        title="Delete"
        color="primary"
        style={{ width: "20px", marginRight: "20px" }}
      >
        <IconButton onClick={props.handleSaveContact}>
          <SaveIcon />
        </IconButton>
      </Tooltip>
      <Tooltip
        hidden={numSelected < 1}
        title="Delete"
        style={{ width: "20px" }}
        color="warning"
      >
        <IconButton onClick={props.handleDelete}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSaveContact: PropTypes.func.isRequired,
};

const useStyles = makeStyles(styles);

export default function EnhancedTable() {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState(new Map());
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const { getUser } = useUserContext();
  const [userData, setUserData] = React.useState(undefined);
  const [searchQuery, setSearchQuery] = useState("");
  const classes = useStyles();
  const selectedMap = new Map();
  const [openDeleteContactDialog, setOpenDeleteContactDialog] =
    React.useState(false);

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const userDataResponse = await action.getMe(token);
    setUserData(userDataResponse);
  };

  useEffect(() => {
    userData === undefined ? fetchData() : fetchData();
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newMap = new Map();
      rows.map((n) => newMap.set(n.id, n));
      setSelected(newMap);

      return;
    }

    selected.clear();
    setSelected(new Map());
  };

  const handleClick = (event, data) => {
    if (selected.has(data.id)) {
      selected.delete(data.id);
    } else {
      selected.set(data.id, data);
    }

    const newMap = new Map(selected);

    setSelected(newMap);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const handleExport = () => {
    // Extracting headers from the first object in the data list
    const contacts = userData?.contacts.slice();
    delete contacts["id"];

    // const headers = Object.keys(contacts[0]);
    // const filteredHeaders = headers.filter(
    //   (header) => header !== "id" && header !== "vcard"
    // );

    const filteredHeaders = [
      "full_name",
      "phone_number",
      "email",
      "date_met",
      "notes",
    ];

    const fileName = `${userData?.links.firstname}_contacts.csv`;

    // Creating an array of arrays, where each inner array represents a row in the CSV
    const csvData = [
      filteredHeaders,
      ...contacts.map((item) => filteredHeaders.map((header) => item[header])),
    ];

    // Converting the array of arrays to CSV format
    const csv = Papa.unparse(csvData);

    // Creating a Blob and a download link to trigger the download
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName || "export.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert(
        "Your browser does not support download functionality. Please try using a different browser."
      );
    }
  };

  const isSelected = (id) => {
    return selected.has(id); //(name) => selected.indexOf(name) !== -1;
  };

  if (!userData) {
    return;
  }

  const rows = userData?.contacts.map((contact) => {
    return createData(
      contact.id,
      contact.full_name,
      contact.phone_number,
      contact.email,
      contact.date_met,
      contact.notes
    );
  });

  const filteredRows = rows.filter(
    (row) =>
      row.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.dateMet.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      row.notes.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const visibleRows = stableSort(
    filteredRows,
    getComparator(order, orderBy)
  ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleDelete = () => {
    setOpenDeleteContactDialog(true);
  };

  const handleSaveContact = async () => {
    const vcard = await generatVCardForContact(
      selected.entries().next().value[1]
    );
    console.log(vcard);
    const blob = new Blob([vcard], {
      type: "text/vcard;charset=utf-8",
    });
    FileSaver.saveAs(blob, "vcard.vcf");
  };

  const deleteContacts = async () => {
    const contactsToKeep = [...userData.contacts];

    for (let contact of contactsToKeep) {
      if (selected.has(contact.id)) {
        delete contactsToKeep[contact.id];
      }
    }

    const filteredContacts = contactsToKeep.filter((contact) => contact);

    for (let i in filteredContacts) {
      delete filteredContacts[i].id;
    }
    const token = (await getUser()?.getIdToken()) || "token";
    const response = await action.updateContact(token, {
      contacts: filteredContacts,
    });
    if (response.status != 201) {
      alert("Could not delete contact(s)");
    } else {
      setUserData(response.data);
      setSelected(new Map());
      setOpenDeleteContactDialog(false);
    }
  };

  return (
    <Box sx={{ width: "100%", overflowX: "auto" }}>
      <Paper sx={{ width: "100%%", mb: 100 }}>
        <DeleteContactDialog
          open={openDeleteContactDialog}
          contacts={selected}
          deleteContacts={deleteContacts}
          closeDeleteContactDialog={() => setOpenDeleteContactDialog(false)}
        />
        <EnhancedTableToolbar
          numSelected={selected.size}
          handleDelete={handleDelete}
          handleSaveContact={handleSaveContact}
        />
        <Button variant="contained" color="primary" onClick={handleExport}>
          Export to CSV
        </Button>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          margin="normal"
          size="small" // Adjust the size for mobile
          sx={{ mb: 2 }} // Add margin-bottom for spacing
          InputProps={{
            sx: {
              borderRadius: 0, // Adjust border-radius as needed
            },
          }}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <TableContainer>
          <div
            className={classes.tableResponsive}
            style={{ paddingLeft: 20, paddingRight: 20 }}
          >
            <Table
              sx={{ minWidth: 100, paddingRight: 500 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.size}
                // order={order}
                // orderBy={orderBy}

                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="right">{row.phone}</TableCell>
                      <TableCell align="right">{row.dateMet}</TableCell>
                      <TableCell
                        align="right"
                        padding="none"
                        style={{
                          wordBreak: "break-word",
                        }}
                      >
                        {row.notes}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          className={classes.button}
          nextIconButtonProps={{
            color: "primary",
            size: "small",
            style: { padding: 0 },
          }}
          backIconButtonProps={{
            color: "primary",
            size: "small",
            style: { padding: 0, marginTrim: 0 },
          }}
        />
      </Paper>
    </Box>
  );
}
