import React, { useState } from "react";
import { useUserContext } from "../Login/userContext";
import * as action from "../../service/action";

import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import { Button, Container, Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import CopyAllIcon from "@mui/icons-material/CopyAll";

export default function AdminForm() {
  const { getUser } = useUserContext();
  const [cardUrl, setCardUrl] = useState("");
  const [cardId, setCardId] = useState("");
  const createCard = async () => {
    let response = await action.createCard(await getUser()?.getIdToken());
    setCardUrl(response.card_url);
    setCardId(response.id);
  };

  return (
    <FormControl fullWidth action={<Link to="/login" />}>
      <Container maxWidth="sm" sx={{ mb: 4 }}></Container>
      <Box sx={{ marginBottom: 4, justifyContent: "center" }}></Box>
      <Button
        variant="outlined"
        color="secondary"
        type="submit"
        onClick={createCard}
      >
        Create Card
      </Button>

      <Alert
        hidden={!cardUrl}
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => navigator.clipboard.writeText(cardUrl)}
          >
            <CopyAllIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {" "}
        {cardUrl}
      </Alert>
      <Alert
        hidden={!cardUrl}
        icon={false}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => navigator.clipboard.writeText(cardId)}
          >
            <CopyAllIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {" "}
        {cardId}
      </Alert>
    </FormControl>
  );
}
