import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyA2s_jzuDAw3nZh3qsbPtwXdtAs-BH1Lqg",
  authDomain: "bappacards-1636a.firebaseapp.com",
  projectId: "bappacards-1636a",
  storageBucket: "bappacards-1636a.appspot.com",
  messagingSenderId: "525337956553",
  appId: "1:525337956553:web:f565bb68764df30f0b3d18",
  measurementId: "G-MLW0H4X0KB",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
