import React from "react";
import {
  FaDollarSign,
  FaUserClock,
  FaMobile,
  FaMapMarkedAlt,
  FaIdCard,
  FaBriefcase,
} from "react-icons/fa";

const iconStyle = (Icon) => <Icon size="3rem" color="#ffffff" />;

export const homeObjOne = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Digital Smart Card",
  headline: "Let your business card make the difference.",
  description:
    "We’re committed to helping your business thrive and grow. A business card that works for you is our top priority.",
  buttonLabel: "Get Started",
  imgStart: "",
  img: require("../../images/17.jpg"),
  alt: "Credit Card",
  start: "",
};

export const homeObjTwo = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Instant Setup",
  headline: "Say goodbye to complex card setup procedures",
  description:
    "Our intuitive platform ensures a smooth and seamless experience from start to finish.",
  buttonLabel: "Learn More",
  imgStart: "",
  img: require("../../images/16.jpg"),
  alt: "Vault",
  start: "",
};

export const homeObjThree = {
  primary: false,
  lightBg: true,
  lightTopLine: false,
  lightText: false,
  lightTextDesc: false,
  topLine: "Abdullah Blake",
  headline: "Showcase your portfolio when networking.",
  description:
    '"With a digital business card, I can now include links to my online portfolio, social media profiles, and even a video introduction. It has not only helped me stand out among competitiors, but has also led to a significant boost in my client inquiries." - Abdullah B.',
  buttonLabel: "Join Today",
  imgStart: "start",
  img: require("../../images/22.jpg"),
  alt: "Vault",
  start: "true",
};

export const homeObjFour = {
  primary: true,
  lightBg: false,
  lightTopLine: true,
  lightText: true,
  lightTextDesc: true,
  topLine: "Secure Database",
  headline: "All your data is stored on our secure server",
  homeDescription:
    "You will never have to worry about your information getting leaked. Our team of security experts will ensure your records are kept safe.",
  buttonLabel: "Join Today",
  imgStart: "start",
  img: require("../../images/17.jpg"),
  alt: "Vault",
  start: "true",
};

export const featuresData = [
  {
    name: "Growth",
    homeDescription:
      "Business growth beyond your imagination. Learn more about your potential by joining today",
    icon: iconStyle(FaBriefcase),
    imgClass: "one",
  },
  {
    name: "Ease of Use",
    homeDescription:
      "Time is money. Our digital business cards deliver an effortless way to connect with you customers.",
    icon: iconStyle(FaMobile),
    imgClass: "two",
  },
  {
    name: "Dedication",
    homeDescription:
      "We always try to improve our product to provide you with the best experience. We know your needs.",
    icon: iconStyle(FaIdCard),
    imgClass: "three",
  },
  {
    name: "Customer Support",
    homeDescription:
      "Our team is available to assist you during business hours. Customer satisfaction is always a priority.",
    icon: iconStyle(FaUserClock),
    imgClass: "four",
  },
  {
    name: "Price",
    homeDescription:
      "An affordable product that will grow your business. Limited time for best prices.",
    icon: iconStyle(FaDollarSign),
    imgClass: "five",
  },
  {
    name: "Worldwide",
    homeDescription:
      "Our digital cards can be used worldwide. Open your business to endless possibilites and opportunities. ",
    icon: iconStyle(FaMapMarkedAlt),
    imgClass: "six",
  },
];
export const featured = [
  {
    cover: "https://cdn-icons-png.flaticon.com/128/9647/9647344.png",
    name: "Buy Card",
    total: "Start Today",
  },
  {
    cover: "https://cdn-icons-png.flaticon.com/128/11091/11091652.png",
    name: "Create Account",
    total: "Manage your info",
  },
  {
    cover: "https://cdn-icons-png.flaticon.com/128/365/365170.png",
    name: "Update Profile",
    total: "Add your socials",
  },
  {
    cover: "https://cdn-icons-png.flaticon.com/128/10134/10134003.png",
    name: "Scan Card",
    total: "Tap card onto phone",
  },
  {
    cover: "https://cdn-icons-png.flaticon.com/128/2189/2189432.png",
    name: "Invite Friends",
    total: "Coming Soon",
  },
];
