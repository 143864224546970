import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AdminForm from "./AdminForm";
import ListProfiles from "./ListProfiles";
import { Stack } from "@mui/material";
import * as action from "../../service/action";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";

import { useUserContext } from "../Login/userContext";

const defaultTheme = createTheme();

export default function Admin() {
  const { getUser } = useUserContext();
  const [userData, setUserData] = useState(undefined);
  const [profilesData, setProfilesData] = useState([]);

  const fetchData = async () => {
    const token = (await getUser()?.getIdToken()) || "token";
    const userDataResponse = await action.getMe(token);
    const profilesData = await action.getProfiles(token);
    setUserData(userDataResponse);
    setProfilesData(profilesData.data);
  };

  useEffect(() => {
    userData === undefined ? fetchData() : fetchData();
  }, []);

  if (!userData || profilesData.length === 0) {
    return <LoadingScreen />;
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Stack direction="row">
        <Container
          component="main"
          maxWidth="xl"
          sx={{ mb: 4, justifyContent: "left", alignItems: "baseline" }}
        >
          <Container maxWidth="sm" sx={{ mb: 4 }}>
            <Paper
              variant="outlined"
              sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
            >
              <Typography component="h1" variant="h4" align="center">
                Admin
              </Typography>

              <React.Fragment>
                <AdminForm />
              </React.Fragment>
              <div style={{ paddingBottom: 5 }}></div>

              <React.Fragment>
                <ListProfiles profilesData={profilesData} />
              </React.Fragment>
            </Paper>
          </Container>
        </Container>
      </Stack>
    </ThemeProvider>
  );
}
