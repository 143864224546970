import React, { useState } from "react";
import { useUserContext } from "../Login/userContext";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import * as action from "../../service/action";
import LoadingButton from "@mui/lab/LoadingButton";
import Alert from "@mui/material/Alert";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const RegisterCard = ({ open, handleClose }) => {
  const { getUser } = useUserContext();

  const [inputCardId, setInputCardId] = useState("");
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const registerCard = async () => {
    setLoading(true);
    const token = (await getUser()?.getIdToken()) || "token";
    const response = await action.registerCard(inputCardId, token);

    if (response.status !== 200) {
      setErrorMessage(response.data.error);
      setShowError(true);
    } else {
      setShowSuccess(true);
    }
    setLoading(false);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          align="center"
        >
          Register Card
        </Typography>
        <Stack direction="column" spacing={2}>
          <TextField
            // error
            value={inputCardId}
            id="filled-error-helper-text"
            label="Card ID"
            required
            onChange={(e) => setInputCardId(e.target.value)}
            defaultValue={inputCardId}
            helperText="CARD ID"
            variant="filled"
          />
          <LoadingButton
            loading={loading}
            disabled={!inputCardId}
            variant="contained"
            onClick={registerCard}
          >
            Submit
          </LoadingButton>
          <Alert
            severity="success"
            onClose={() => setShowSuccess(false)}
            hidden={!showSuccess}
          >
            Your card has been successfully registered!
          </Alert>
          <Alert
            severity="error"
            onClose={() => setShowError(false)}
            hidden={!showError}
          >
            {errorMessage}
          </Alert>
        </Stack>
      </Box>
    </Modal>
  );
};
export default RegisterCard;
